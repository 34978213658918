import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

function ConfirmationDialog({showModal, setShowModal, onConfirmAction, onCancelAction=false, title="Confirmation", description="Are you sure?", cancelText="Cancel", confirmText="Ok"}) {

  const handleConfirmAction = () => {
    if(onConfirmAction){
      onConfirmAction();
    }
    setShowModal(false);
  };

  const handleCloseModal = () => {
    if(onCancelAction){
      onCancelAction()
    }
    setShowModal(false);
  };

  return (
    <Dialog open={showModal} onClose={handleCloseModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{description}</p>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseModal} color="primary">
          {cancelText}
        </Button>
        <Button variant="contained" onClick={handleConfirmAction} color="primary">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
