import { downloadCSV } from "react-admin";
import jsonExport from 'jsonexport/dist';
import { getFormattedDate, getOptionSelectedDisplay } from "../../utils/utilFunctions";

const headerOrder = [
  'createdDate', 
  'firstName', 
  'lastName', 
  'businessName', 
  'phoneNumber',
  'email',
  'address',
  'accountNumber',
  'customerType',
  'subscriptionType',
  'amount',
  'wantedAmount',
  'optionSelected',
  'status',
  'statusDate',
  'statusUserEmail',
  'operatingCompany'
]

const headerNames = [
  'Sign Up Date', 
  'First Name', 
  'Last Name', 
  'Business Name', 
  'Phone Number',
  'Email',
  'Address',
  'Account Number',
  'Customer Type',
  'Subscription Type',
  'Subscription Amount',
  'Subscription Amount If Not Capped',
  'Option Selected',
  'Status',
  'Status Date',
  'Status Updated By',
  'Operating Company'
]

export const subsExporter = subscriptions => {
  const subsForExport = subscriptions.map(sub => {
      const { id, streetAddress, city, zip, state, ...subForExport } = sub; // omit id
      subForExport.createdDate = getFormattedDate(sub.createdDate);
      subForExport.statusDate = getFormattedDate(sub.statusDate);
      subForExport.address = `${sub.streetAddress}, ${sub.city}, ${sub.state}, ${sub.zip}`;
      subForExport.optionSelected = getOptionSelectedDisplay(sub);
      subForExport.phoneNumber = subForExport.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      return subForExport;
  });
  jsonExport(subsForExport, {
      headers: headerOrder,
      rename: headerNames
  }, (err, csv) => {
      downloadCSV(csv, `subscriptions_${new Date().toLocaleDateString("en-US")}`);
  });
};
