import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { ListButton, Show, TopToolbar } from "react-admin";
import SubscriptionInformation from './SubscriptionInformation';

const SubscriptionTitle = ({ record }) => {
  return <span>Subscription {record ? `"${record.firstName} ${record.lastName}"` : ''}</span>;
}

const SubscriptionShowActions = ({ basePath, data }) => {
  return(
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />}/>
    </TopToolbar>
  )
};

const SubscriptionShow = props => {
  return (
    <Show title={<SubscriptionTitle/>} actions={<SubscriptionShowActions />} {...props}>
      <SubscriptionInformation props={props}/>    
    </Show>
  )
};
export default SubscriptionShow;
