import { getUserFromLocalStorage } from "../utils/utilFunctions";

const getFileKey = (subscriptionId) => {
  const { id_token } = getUserFromLocalStorage();
  const options = { 
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${id_token}`
    }
  }
  return fetch(`/api/admin/file/key/${subscriptionId}`, options)
           .then((response) => response.json())
}

const getFile = (key) => {
  const options = { 
    method: 'GET', 
    headers: {
      'Accept': 'application/pdf'
    }
  }
  return fetch(`/api/file/${key}`, options)
           .then(res => res.blob())
}

const uploadFile = (file, subscriptionId, type) => {
  const { id_token } = getUserFromLocalStorage();
  let formData = new FormData();
  formData.append("file", file)
  const options = { 
    method: 'POST',     
    headers: {
      'Authorization': `Bearer ${id_token}`
      // "Content-Type": "multipart/form-data",
    },
    body: formData
  }
  const postUrl = `/api/file/upload?type=${type}&subscriptionId=${subscriptionId}`

  return fetch(postUrl, options)
    .then((response) => response.json())
}

export {
  getFileKey,
  getFile,
  uploadFile
}