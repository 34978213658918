import simpleRestProvider from 'ra-data-simple-rest'
import dataProviderHttpClient from './dataProviderHttpClient';
//import { cacheDataProviderProxy } from 'react-admin';

//This data provider will handle all filtering and sorting client-side

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, dataProviderHttpClient);

let cachedData = {};

const filterAndSortData = (data, params) => {
  const { field, order } = params.sort;
  if (params.filter){
    let filters = [];
    for (const key in params.filter) {
      filters.push(item => item[key] === params.filter[key])
    }
    const result = data.filter(item => filters.every(f => f(item)));   
    return {
      data: result.sort(dynamicSort(field, order)),
      total: result.length
    }
  }else{
    return {
      data: data.sort(dynamicSort(field,order)),
      total: data.length
    }
  }
}

const dynamicSort = (property, order) => {
  let sortOrder = 1;
  if (order === "DESC") {
      sortOrder = -1;
  }
  return function (a, b){
      let aProp = a[property];
      let bProp = b[property];
      if (!a.hasOwnProperty(property)) {
          aProp = ''
      }
      if (!b.hasOwnProperty(property)) {
          bProp = ''
      }
      const result = (aProp < bProp) ? -1 : (aProp > bProp) ? 1 : 0;
      return result * sortOrder;
  }
}

const renewDataProvider = {
  ...dataProvider,
  //override only the getList to do client-side filtering and sorting
  getList: (resource, params) => {
    let results;
    if (resource in cachedData){ 
      results = cachedData[resource];
    }else{
      results = dataProvider.getList(resource, params);
      cachedData[resource] = results;
    }
    return results.then(({ data }) => (filterAndSortData(data, params))); 
  },
  update: (resource, params) => {
    delete cachedData[resource];
    return dataProvider.update(resource, params);
  },
  invalidateCache: (resource) => {
    let invalidateCachePromise = new Promise(function(myResolve, myReject) {
      let result = delete cachedData[resource];
      if (result) {
        myResolve({data: {cacheInvalidated: true}});
      } else {
        myReject("Error");
      }
    });
    
    return invalidateCachePromise;
  }
}

//export default cacheDataProviderProxy(renewDataProvider, 5 * 60 * 1000);
export default renewDataProvider;