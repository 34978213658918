import { getUserFromLocalStorage } from "../utils/utilFunctions";

const postVerifyContract = (subscriptionId) => {  
  const { id_token } = getUserFromLocalStorage();
  const options = { 
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${id_token}`
    }
  }
  return fetch(`/api/admin/subscriptions/${subscriptionId}/contract/verify`, options)
           .then((response) => response.json())
}

const postContractExecuted = (subscriptionId) => {  
  const { id_token } = getUserFromLocalStorage();
  const options = { 
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${id_token}`
    }
  }
  return fetch(`/api/admin/subscriptions/${subscriptionId}/contract/executed`, options)
           .then((response) => response.json())
}

const postSendContract = (subscriptionId, sendToInfo) => {  
  const { id_token } = getUserFromLocalStorage();
  const options = { 
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${id_token}`
    },
    body: JSON.stringify(sendToInfo)
  }
  return fetch(`/api/admin/subscriptions/${subscriptionId}/contract/send`, options)
           .then((response) => response.json())
}

const updateSubscription = (subscriptionId, updatedAttributes) => {  
  const { id_token } = getUserFromLocalStorage();
  const options = { 
    method: 'PUT', 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${id_token}`
    },
    body: JSON.stringify(updatedAttributes)
  }
  return fetch(`/api/admin/subscriptions/${subscriptionId}`, options)
           .then((response) => response.json())
}

const updateStatus = (subscriptionId, updatedStatus) => {  
  const { id_token } = getUserFromLocalStorage();
  const options = { 
    method: 'PUT', 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${id_token}`
    },
    body: JSON.stringify({"newStatus": updatedStatus})
  }
  return fetch(`/api/admin/subscriptions/${subscriptionId}/status`, options)
           .then((response) => response.json())
}


export {
  postVerifyContract,
  postContractExecuted,
  postSendContract,
  updateSubscription,
  updateStatus
}