export const subscriptionTypes = {
  GREEN_PROMISE: "Green Promise",
  GEAUX_GREEN: "Geaux Green",
  GREEN_PRICING: "Green Pricing",
  GREEN_SELECT: "Green Select",
  RCO: "RCO",
  GS_LARGE_VOLUME: "Green Select - LV",
  GO_ZERO: "Go Zero",
  GREEN_FUTURE: "Green Future",
}

export const subTypesForUpload = [
  subscriptionTypes.GO_ZERO
]

export const requireContract = [
  subscriptionTypes.GREEN_PROMISE,
  subscriptionTypes.GO_ZERO
]


export const haveViewableAmounts = [
  subscriptionTypes.GREEN_PROMISE,
  subscriptionTypes.RCO,
  subscriptionTypes.GS_LARGE_VOLUME
]