import { Grid, TextField, CircularProgress, Button, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react";
import { updateSubscription } from '../../api/AdminAPI';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "1rem"
  },
}));

const TextFieldEditable = props => {
  const {name, label, value="", onChange} = props;

  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      required
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      inputProps={{
        maxLength: 50,
      }}
      error={value.trim() === ""}
      helperText={value.trim() === "" ? "Required" : ""}
    />
  )
}

const SubscriptionCustomerInfoEdit = props => {
  const classes = useStyles();
  const subscription = props?.subscription;
  const onClose = props?.onClose;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: subscription?.email ? subscription?.email : "",
    firstName: subscription?.firstName ? subscription?.firstName : "",
    lastName: subscription?.lastName ? subscription?.lastName : "",
    streetAddress: subscription?.streetAddress ? subscription?.streetAddress : "",
    city: subscription?.city ? subscription?.city : "",
    state: subscription?.state ? subscription?.state : "",
    zip: subscription?.zip ? subscription?.zip : "",
    phoneNumber: subscription?.phoneNumber ? subscription?.phoneNumber : "",
    amount: subscription?.amount ? subscription?.amount : "",
    accountNumber: subscription?.accountNumber ? subscription?.accountNumber : "",
    optionSelected: subscription?.optionSelected ? subscription?.optionSelected : "",
    businessName: subscription?.businessName ? subscription?.businessName : "",
  });

  const isCommercial = subscription.customerType === 'Commercial';

  const handleChange = (event) => {    
    let key = event.target.name;
    let value = event.target.value;
    setFormData({
        ...formData,
        [key]: value
    });
  };

  const validateRequired = () => {
    const required = (formData.email
      && formData.firstName
      && formData.lastName
      && formData.streetAddress
      && formData.city
      && formData.state
      && formData.zip
      && formData.phoneNumber
      && formData.amount
      && formData.accountNumber
      && formData.optionSelected
    );

    if (isCommercial) {
      return required && formData.businessName;
    }else{
      return required;
    }

  }

  const validateEmailFormat = (email) => {
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
    return regex.test(email)
  }

  const enableSubmit = () => {
    return validateRequired()
        && validateEmailFormat(formData.email)

  }

  const handleSubmit = () => {
    setLoading(true);    
    updateSubscription(subscription?.id, formData).then(() => {
        setLoading(false);
        onClose();
    }).catch((error) => {
        setLoading(false);
        console.log(error);
    });
  }
  return (
    <div>
      <form noValidate autoComplete="false" onSubmit={() => handleSubmit()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4">Edit Subscription</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Subscription Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldEditable value={formData.accountNumber} name="accountNumber" label="Account Number" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.amount} name="amount" label="Subscription Amount" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              select
              name="optionSelected"
              label="Option Selected"
              value={formData.optionSelected}
              onChange={handleChange}
              SelectProps={{
                  native: true,
                }}
              error={formData.optionSelected.trim() === ""}
              helperText={formData.optionSelected.trim() === "" ? "Required" : ""}
            >   
              <option key={""} value={""}>
                  {""}
              </option>             
              <option key={"Fixed Price"} value={"fixedPrice"}>
                  {"Fixed Price"}
              </option>
              <option key={"Variable Price"} value={"variablePrice"}>
                  {"Variable Price"}
              </option>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Customer Information</Typography>
          </Grid>
          {isCommercial && 
            <Grid item xs={12}>
              <TextFieldEditable value={formData.businessName} name="businessName" label="Business Name" onChange={handleChange} />
            </Grid>
          }
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.firstName} name="firstName" label="First Name" onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.lastName} name="lastName" label="Last Name" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.streetAddress} name="streetAddress" label="Address" onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.city} name="city" label="City" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.state} name="state" label="State" onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.zip} name="zip" label="Zip" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.phoneNumber} name="phoneNumber" label="Phone Number" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.email} name="email" label="E-mail" onChange={handleChange} />
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button 
              className={classes.button} 
              disabled={loading} 
              onClick={onClose} 
              variant="outlined" 
              color="primary">
                Cancel
            </Button>
            {loading ? 
              <CircularProgress color="primary" size={36} /> 
              :
              <Button
                className={classes.button}
                id="submit"
                type="submit"
                variant="contained"
                color="primary"
                disabled={!enableSubmit()}
                onClick={handleSubmit}>
                  Update Subscription
              </Button>
            }
          </Grid>
        </Grid>
      </form>
    </div>
  )
};
export default SubscriptionCustomerInfoEdit;