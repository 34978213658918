
import { useEffect } from 'react';
import { useLogin } from 'react-admin';
import { useHistory } from 'react-router-dom';


const OauthLogin = () => {
  const login = useLogin();
  const history = useHistory();
  const location = window.location.href;
  const url = new URL(window.location.href);
  const { searchParams } = url ;
  const error = searchParams.get('error');
  const code = searchParams.get('code');
  //const state = searchParams.get('state');

  useEffect(() => {
    if (error){
      // Do nothing, error will be displayed
    } else if (code) {
      // If code is present, we came back from the provider
      // Login and if successful redirect to main page for now
      login({ location }).then(() => history.push("/"));
    } else {
      // Do not provide code, just trigger the redirection (via authProvider)
      login(); 
    }
  }, [login, history, location, code, error]);

  if (error){
    return (
      <div>
          <div>Error retrieving User Information!</div>
          { (error === "access_denied") ?
              <div>If you logged in using your full e-mail address, please re-login using only your Entergy username</div>
              : <div>An unknown error occurred</div>
          }
      </div>)
  }
  return (<div>Logging in...</div>);
};

export default OauthLogin;