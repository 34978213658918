import { useParams } from "react-router";
import { Container, Grid, Link, Box, Button, FormControlLabel, Checkbox, TextField, Snackbar, Typography } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from "@material-ui/core/styles";
import CheckedIcon from "../components/CheckedIcon";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getValidateSubscriptionContract, postSignSubscriptionContract } from "../api/SubscriptionAPI";
// import { getFile } from "../api/FileAPI";

const useStyles = makeStyles(() => ({
  mainDiv:{
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  header:{
    padding: ".75rem 2rem 1rem 2rem",
    background: "#fff",
    color: "#333"
  },
  body:{
    background: "red",
    height: "100%"
  },
  link:{
    color: "#fefefe",
    fontSize: "1.125rem",
    fontWeight: "700",
    lineHeight: "30px",
  },
  disclaimer:{
    fontSize: "0.75rem",
    marginTop: "1rem",
    fontWeight: "700"
  },
  footer:{
    padding: "1rem 0",
    background: "#0a0a0a",
    color: "#fefefe",  
  },
  footerContent:{
    padding: "0 2rem 0 2rem"
  },
  subHeader: {
    color: "#505A5F",
    fontWeight: "bold",
    marginTop: ".6rem"
  },
  termsBox: {
    margin: "1.3rem 0px",
    // backgroundColor: "#E1E2E3",
    padding: "2rem",
    overflow: "auto"
  },
  termsText: {
    color: "#505A5F",
    padding: ".1rem"
  },
  checkbox: {
    margin: ".6rem 0rem",
    width: "80%"
  },
  instructions: {
    margin: ".6rem 0rem",
    width: "80%"
  },
  checkboxBox: {
    paddingTop: "0",
    alignSelf: "start",
    color: '#979797'  
  },
  nameField:{
    width: "60%",
    backgroundColor: "#fff",
  },
  submitButton: {
    fontWeight: 700,
    borderRadius: 100,
    height: 40,
    backgroundColor: "#ff1a58",
    marginTop: "1.3rem",
    marginBottom: "4rem",
    width: "18rem",
    display: "block",
    '&:hover': {
      backgroundColor: '#fff',
      color: '#ff1a58',
      border: "2px solid #ff1a58"
    }
  }
}));


const CustomerContract = () => {
  const { id, key } = useParams();
  const classes = useStyles();
  const [hasViewed, setHasViewed] = useState(false);
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitError, setSubmitError] = useState("")

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNameChange = (event) => {
    setName(event.target.value)
  };

  const handleSubmit = (event) => {
    if (!name || !checked){
      setSubmitError('You must agree to terms and fill out your name exactly as it appears on the contract.')
    }else{
      setLoading(true);
      postSignSubscriptionContract(id, name)
        .then((response) => {
          setLoading(false);
          console.log(response);
          if (response?.error){
            setSubmitError(response?.errorDetails)
          }else if (response?.success){
            setMessage("Thank you. The contract has been submitted. Please allow up to 48 hours for processing. The Green Promise subscription should appear on your bill after processing.")
          }
        })
    }
  }

  const openInNewTab = () => {
    setHasViewed(true);
    const win = window.open(`/api/file/${key}`, "_blank");
    win.focus();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSubmitError("");
};

  useEffect(() => {    
    getValidateSubscriptionContract(id, key)
      .then((response) => {
        console.log(response)
        if (!response?.valid){
          setMessage("We're sorry but the link provided is invalid or the contract has expired.")
        }
        setLoading(false);
      })
      .catch(error => {
          setMessage("We're sorry but the link provided is invalid or the contract has expired.");
          setLoading(false);
      });          
  }, [id, key]);

  const renderBody = () => {
    if (loading) return <Container><Grid container xs={12} justifyContent="center"><CircularProgress  size={'8rem'}/></Grid></Container>;
    if (message) return <Container><Grid container xs={12} justifyContent="center">{message}</Grid></Container>;
    return (
      <Container>
        {/* <Typography className={classes.subHeader} variant="h5">Accepting of Entergy Arkansas Green Promise terms</Typography> */}
        <Box className={classes.termsBox} justifyContent="center">
          <Grid container>
            <Grid container xs={12} justifyContent="center" style={{marginBottom: "3rem"}}> 
              <Typography className={classes.instructions} variant="subtitle1">
                1. Please review the contract to verify name, date, and information is correct and the associated subscription terms. <br />
                2. You will notice fields for your signature, date, and counter signature are not complete; those items will be completed once you close the review window and complete the below acknowledgement and digitally sign.<br />
                3. Upon submittal your account will be enrolled, and you will be contacted via email.<br />
              </Typography>
            </Grid>
            <Grid container xs={12} justifyContent="center" style={{marginBottom: "3rem"}}>
              <Button size="large" onClick={openInNewTab} color="primary" startIcon={<AssignmentIcon />}> View Contract </Button>
            </Grid>
            <Grid container xs={12} justifyContent="center"> 
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  className={classes.checkboxBox}
                  checked={checked}
                  onChange={handleCheckboxChange}
                  name="acceptTerms"
                  color="primary"
                  disabled={!hasViewed}
                  checkedIcon={<CheckedIcon />}
                />
              }
              label="I understand that by typing my name in below (exactly as it appears in the contract), I am signing this document, agreeing to the Entergy Arkansas Green Promise terms stated in the contract and choose 
              to enroll with the subscription rate(s) stated in the contract."
            />
            </Grid>
            <Grid container xs={12} justifyContent="center" style={{marginBottom: "3rem"}}>
              <TextField
                className={classes.nameField}
                variant="outlined"
                margin="normal"
                name="name"
                label="Name"
                onChange={handleNameChange}
                value={name}
                disabled={!hasViewed}
                required
                inputProps={{
                  maxLength: 120
                }}
              />
            </Grid>
            <Grid container xs={12} justifyContent="center">
              <Button className={classes.submitButton} variant="contained" color="primary" onClick={handleSubmit} disabled={!hasViewed}>Submit</Button>
            </Grid>
          </Grid>
        </Box>
      </Container>            
    )
  }

  return(
    <div className={classes.mainDiv}>
      <Container maxWidth={false} className={classes.header}>
        <Grid container>
          <Grid container item xs={6} justify="flex-start" alignItems="center">
            <img src="/img/entergyLogo.svg" alt="Entergy Logo" width="144"></img>
          </Grid>
          <Grid container item xs={6} justify="flex-end" alignItems="center">
          </Grid>
        </Grid>
      </Container>
      {renderBody()}
      <Container maxWidth={false} className={classes.footer}> 
        <Grid container className={classes.footerContent}> 
          <Grid xs={12}>   
            <Link underline="hover" href="https://www.entergy.com/privacy-policy/" target="_blank" rel="noreferrer" className={classes.link}>
              Privacy Policy
            </Link>
          </Grid>
          <Grid xs={12}>   
            <Link underline="hover" href="https://www.entergy.com/terms-of-use/" target="_blank" rel="noreferrer" className={classes.link}>
              Terms of Use
            </Link>
          </Grid>
          <Grid xs={12} className={classes.disclaimer}>   
            {"© "}{new Date().getFullYear()} Entergy Corporation, All Rights Reserved. The Entergy name and logo are registered service marks of Entergy Corporation and may not be used without the express, written consent of Entergy Corporation.
          </Grid>            
        </Grid> 
        <Snackbar open={submitError} onClose={handleClose}>
          <Alert severity="error" variant="filled" onClose={handleClose}>
              {submitError}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  )
};

export default CustomerContract;