import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
checkBox: {
    display: 'flex',
    position: 'relative',
    color: '#979797'  
    },
  checkMark: {
    bottom: '.2rem',
    left: '.2rem',
    position: 'absolute',
    color: '#3576CB'
    }
}));

export default function CheckedIcon() {
  const classes = useStyles();
  return (
      <div className={classes.checkBox}>
      <svg className="MuiSvgIcon-root">
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
      </svg>
      <svg className={`MuiSvgIcon-root ${classes.checkMark}`}>
        <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/>
      </svg>
    </div>
    );
}
