import { fetchUtils } from 'react-admin';
import { getUserFromLocalStorage } from './utilFunctions';

const dataProviderHttpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const { id_token } = getUserFromLocalStorage();
  options.headers.set('Authorization', `Bearer ${id_token}`);
  return fetchUtils.fetchJson(url, options);
};

export default dataProviderHttpClient;