import { Grid, Modal } from "@material-ui/core";
import { ChipField, Datagrid, List, Pagination, SelectInput, TextField, TextInput, FunctionField, FilterButton, ExportButton, Button, TopToolbar } from "react-admin";
import { statuses } from "../../constants/statuses";
import { customerTypes } from "../../constants/customerTypes"
import { makeStyles } from '@material-ui/core/styles';
import { getFormattedDate, getOptionSelectedDisplay } from "../../utils/utilFunctions";
import { subsExporter } from "./subsExporter";
import PublishIcon from '@material-ui/icons/Publish';
import UploadSubscription from "./UploadSubscription";
import { useState } from "react";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles({
  headerCell: {
      backgroundColor: '#E1E2E3',
      fontWeight: 'bold'
  },
  modal: {
    position: 'absolute',
    background: '#fafafa',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem'
  }
});

const SubscriptionPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const subscriptionFilters = [
  <SelectInput source="customerType" choices={customerTypes} />,
  <SelectInput source="status" choices={statuses} />,
  <TextInput source="firstName" />,
  <TextInput source="lastName" />,
  <TextInput source="accountNumber" />,
  <TextInput source="amount" />,
];

const SubscriptionListActions = (props) => {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  return (
    <TopToolbar>
      <Button
        onClick={() => { setOpenUploadModal(true); }}
        label="Upload Subscriptions"
      >
        <PublishIcon/>
      </Button>
      <FilterButton/>
      <ExportButton/>
      
      <Modal open={openUploadModal} onClose={handleCloseUploadModal}>
        <Grid style={modalStyle} className={classes.modal}>
          <UploadSubscription onClose={handleCloseUploadModal}/>
        </Grid>
      </Modal>
    </TopToolbar>
  )
};

const SubscriptionList = props => {
  const classes = useStyles();

  const displayMultiple = (value) => {
    if (value?.includes(";")) {
      return "Multiple"
    }
    return value
  }

  return (    
    <List {...props} 
      actions={<SubscriptionListActions/>}
      title="Renew Admin Portal" 
      bulkActionButtons={false} 
      filters={subscriptionFilters} 
      perPage={5000} 
      exporter={subsExporter}
      pagination={<SubscriptionPagination/> }
      sort={{ field: 'createdDate', order: 'DESC' }}
    >
      <Datagrid classes={classes} rowClick="show" {...props}>
        {/* <TextField source="id" /> */}
        <FunctionField label="Sign Up Date" render={record => getFormattedDate(record?.createdDate)} />
        <TextField source="operatingCompany" sortable={false}/>
        <TextField source="subscriptionType" />
        <TextField source="customerType" />
        <ChipField source="status" />
        <FunctionField label="Status Date" render={record => getFormattedDate(record?.statusDate)} />
        <FunctionField label="Name" render={record => record.businessName ? record.businessName : `${record.firstName} ${record.lastName}`}/>
        <FunctionField label="Account No." render={record => displayMultiple(record?.accountNumber)}/>
        <FunctionField
          label={"Option Selected"}
          render={record => getOptionSelectedDisplay(record, true)}
        />
        <FunctionField label="Amount" render={record => displayMultiple(record?.amount)}/>
      </Datagrid>
    </List>
    )
};
export default SubscriptionList;