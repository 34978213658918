import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../images/entergy-logo.png';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 700,
    paddingTop: '.5rem',
    marginLeft: '-.5rem'
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: "40px",
  },
});

const CustomAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar {...props} color='secondary'>
      <Toolbar>
        <img src={logo} alt="logo" className={classes.logo} />
      </Toolbar>
      <Typography
        variant="h6"
        color="primary"
        className={classes.title}
      >Renew Admin Portal</Typography>
      {/* <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      /> */}
    </AppBar >
  );
};

export default CustomAppBar;