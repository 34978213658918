import { Grid, Typography, CircularProgress} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import PublishIcon from '@material-ui/icons/Publish';
import { Button } from 'react-admin';
import { uploadFile } from "../../api/FileAPI";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "1rem"
    
  },
  hidden: {
    display: "none"
  }
}));

const UploadSubscription = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState('');
  const onClose = props?.onClose;
  const GO_ZERO_SUBSCRIPTION_UPLOAD = "go-zero-subscription-upload"
  const GEAUX_GREEN_SUBSCRIPTION_UPLOAD = "geaux-green-subscription-upload"
  const GO_ZERO_FILE_KEY = "subscription-templates-go-zero"
  const GEAUX_GREEN_FILE_KEY = "subscription-templates-geaux-green"

  const openInNewTab = (key) => {
    const win = window.open(`/api/file/${key}`, "_blank");
    win.focus();
    // getFile(key)
    //   .then(blob => {
    //     const file = window.URL.createObjectURL(blob);
    //     const win = window.open(file, "_blank");
    //     win.focus();
    //   }); 
  }

  const handleUpload = (event, subscriptionType) => {
    setLoading(subscriptionType)
    uploadFile(event.target.files[0], "", subscriptionType)
      .then((response) => {
        if (response?.success){
          setLoading('')     
          window.location.reload()          
        } else{
          setLoading('')    
          onClose()    
          alert(`Error creating subscription: ${response.error}`)
        }         
      })
      .catch((error) => {
        setLoading('');
        onClose()
        alert("Error occurred uploading subscription.")                
      });
  }

  return (
    <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Supported Subscriptions:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">EAL - Go Zero</Typography>
            <Button 
              component="span"
              onClick={() => openInNewTab(GO_ZERO_FILE_KEY)} 
              variant="text"
              label="Go Zero Sample Template File" 
              color="primary">          
            </Button>
          </Grid>
          <Grid container item xs={12} sm={6} justifyContent="flex-end">
            <div>
              <input 
                className={classes.hidden}
                id="subUploadGoZero"
                type="file" 
                accept=".xlsx" 
                name="subUploadInputGoZero" 
                label="Select A File" 
                onChange={(e) => { handleUpload(e, GO_ZERO_SUBSCRIPTION_UPLOAD); }} 
              />
              <label htmlFor="subUploadGoZero">
                <Button
                  className={classes.button}
                  component="span"
                  variant="contained"
                  color="primary"
                  startIcon={loading === GO_ZERO_SUBSCRIPTION_UPLOAD ? <CircularProgress color="inherit" size={'1rem'}/> : <PublishIcon />}
                  label="Upload Go Zero"
                />
              </label>
            </div>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">ELL - Geaux Green</Typography>
            <Button 
              component="span"
              onClick={() => openInNewTab(GEAUX_GREEN_FILE_KEY)} 
              variant="text"
              label="Geaux Green Sample Template File" 
              color="primary">          
            </Button>
          </Grid>
          <Grid container item xs={12} sm={6} justifyContent="flex-end">
            <div>
              <input 
                className={classes.hidden}
                id="subUploadGeauxGreen"
                type="file" 
                accept=".xlsx" 
                name="subUploadInputGeauxGreen" 
                label="Select A File" 
                onChange={(e) => { handleUpload(e, GEAUX_GREEN_SUBSCRIPTION_UPLOAD); }} 
              />
              <label htmlFor="subUploadGeauxGreen">
                <Button
                  className={classes.button}
                  component="span"
                  variant="contained"
                  color="primary"
                  startIcon={loading === GEAUX_GREEN_SUBSCRIPTION_UPLOAD ? <CircularProgress color="inherit" size={'1rem'}/> : <PublishIcon />}
                  label="Upload Geaux Green"
                />
              </label>
            </div>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button 
              className={classes.button}
              component="span"
              onClick={onClose} 
              variant="outlined"
              label="Cancel" 
              color="primary">          
            </Button>
          </Grid>
        </Grid>
    </div>
  )
};
export default UploadSubscription;