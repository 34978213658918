import { Button, Labeled, TextField, FunctionField, useRecordContext, useRefresh, DateField } from 'react-admin';
import { useState, useEffect } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EmailIcon from '@material-ui/icons/Email';
import PublishIcon from '@material-ui/icons/Publish';
import { Grid, CircularProgress, Modal } from "@material-ui/core";
import { getFileKey, uploadFile } from '../../api/FileAPI';
import { postContractExecuted, postVerifyContract } from '../../api/AdminAPI';
import SendToInformation from './SendToInformation';
import { makeStyles } from "@material-ui/core/styles"
import { subscriptionTypes } from '../../constants/subscriptionTypes';
import { isEmptyObject } from '../../utils/utilFunctions';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    background: '#fafafa',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem'
  },
  hidden: {
    display: "none"
  }
}));

const ContractInformation = props => {
  const CONTRACT_FILE_TYPE = "customer-contract"
  const CONTRACT_UPLOADED = "admin-uploaded-contract"
  const classes = useStyles();
  const record = useRecordContext();
  const refresh = useRefresh();
  const subscriptionId = record?.id;
  const isCommercial = record?.customerType === 'Commercial';
  const status = record?.status;
  const [keyMap, setKeyMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [hasViewed, setHasViewed] = useState(false);
  const [openSendInfo, setOpenSendInfo] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const contractNeedsVerifying = () => {
    if (status && status === 'Customer Contract Review' && !record?.contractVerifiedDate){
      return true;
    }
    return false;
  }

  const commercialContractReadyToSend = () =>{
    if (record?.subscriptionType === subscriptionTypes.GREEN_PROMISE && isCommercial && record?.contractVerifiedDate 
      && status && (status === 'Customer Contract Review' || status === 'Customer Contract Pending') ){
      return true;
    }
    return false;
  }

  const canUploadContract = () => {
    if (record?.subscriptionType === subscriptionTypes.GO_ZERO && isCommercial && record?.contractVerifiedDate 
      && status && (status === 'Customer Contract Review' || status === 'Pending Enrollment') ){
      return true;
    }
    return false;
  }

  const contractCanBeExecuted = () => {
    if (status && status === 'Customer Contract Review' && (CONTRACT_UPLOADED in keyMap)){
      return true;
    }
    return false;
  }

  function residentialContractReadyToSendAgain() {
    if (record?.subscriptionType === subscriptionTypes.GREEN_PROMISE && !isCommercial && status && status === 'Customer Contract Pending') {
      return true;
    }
    return false;
  }

  const handleUpload = (event) => {
    setLoading(true)
    uploadFile(event.target.files[0], subscriptionId, CONTRACT_UPLOADED)
      .then((response) => {
        if (response?.success){
          setLoading(false) 
          getContractFileKeys()         
          refresh()
        } else{
          setLoading(false) 
          setError('Error uploading file.')
        }         
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
        setError('Error uploading file.')
      });
  }

  const handleOpenSendInfo = () => {
    setOpenSendInfo(true);
  };

  const handleCloseSendInfo = () => {
    setOpenSendInfo(false);
    refresh();
  };

  const openInNewTab = (key) => {
    setHasViewed(true);
    const win = window.open(`/api/file/${key}`, "_blank");
    win.focus();
    // getFile(key)
    //   .then(blob => {
    //     const file = window.URL.createObjectURL(blob);
    //     const win = window.open(file, "_blank");
    //     win.focus();
    //   }); 
  }

  const verifyContract = () => {
    setLoading(true);
    postVerifyContract(subscriptionId)
      .then(responseJson =>{
        setLoading(false);
        refresh();
      })
      .catch(error => {
        setError("Error Verifying Contract.");
    });
  }

  const markContractExecuted = () => {
    setLoading(true);
    postContractExecuted(subscriptionId)
      .then(responseJson =>{
        setLoading(false);
        refresh();
      })
      .catch(error => {
        setError("Error Marking Contract Executed.");
    });
  }

  const getContractFileKeys = () => {
    getFileKey(subscriptionId)
      .then((results) => {
        setKeyMap(results);
        setLoading(false);
      })
      .catch(error => {
          setError("Error Loading Contract.");
          setLoading(false);
      })  
  }

  useEffect(() => {    
    getContractFileKeys() 
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [subscriptionId]);

  if (loading) return <CircularProgress  size={'1.6rem'}/>;
  if (error) return <div>{error}</div>;
  if (isEmptyObject(keyMap)) return null;
  return (
    <>
      <Grid container spacing={3}>
        { CONTRACT_FILE_TYPE in keyMap &&
          <Grid item xs={12} sm={6} md={4}>
            <Button variant="outlined" onClick={() => openInNewTab(keyMap[CONTRACT_FILE_TYPE])} label="View Contract" startIcon={<AssignmentIcon />}/>
          </Grid>
        }
        { CONTRACT_UPLOADED in keyMap &&
          <Grid item xs={12} sm={6} md={4}>
            <Button variant="outlined" onClick={() => openInNewTab(keyMap[CONTRACT_UPLOADED])} label="View Executed Contract" startIcon={<AssignmentIcon />}/>
          </Grid>
        }
        { contractNeedsVerifying() &&
          <Grid item xs={12} sm={6} md={4}>
            <Button variant="outlined" onClick={verifyContract} label="Mark Contract Verified" disabled={!hasViewed} startIcon={<AssignmentTurnedInIcon />}/>
          </Grid>
        }
        { (commercialContractReadyToSend() || residentialContractReadyToSendAgain()) &&
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="outlined" 
              onClick={handleOpenSendInfo} 
              label={status === 'Customer Contract Review' ? "Email Contract" : "Re-send Contract"}
              startIcon={<EmailIcon />}/>
          </Grid>
        }
        { canUploadContract() &&
          <Grid item xs={12} sm={6} md={4} >
            <input 
              className={classes.hidden}
              id="adminContractUpload"
              type="file" 
              accept=".pdf" 
              name="contractUpload" 
              label="Select A File" 
              onChange={(e) => { handleUpload(e); }} 
            />
            
            <label htmlFor="adminContractUpload">
              <Button
                variant="outlined"
                component="span"
                label={status === 'Customer Contract Review' ? "Upload Contract" : "Re-upload Contract"}
                startIcon={<PublishIcon />}
              />
            </label>
          </Grid>          
        }
        { contractCanBeExecuted() &&
          <Grid item xs={12} sm={6} md={4}>
            <Button variant="outlined" onClick={markContractExecuted} label="Mark Contract Executed" startIcon={<AssignmentTurnedInIcon />}/>
          </Grid>
        }
        {/* empty line to separate buttons */}
        <Grid item xs={12}></Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Labeled label="Contract Created Date">
            <DateField source="contractCreatedDate" />
          </Labeled>
        </Grid>
        {isCommercial && 
          <>
            <Grid item xs={12} sm={6} md={4}>
              <Labeled label="Contract Verified Date">
                <DateField source="contractVerifiedDate"></DateField>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Labeled label="Contract Verified By">
                <TextField source="contractVerifiedBy"></TextField>
              </Labeled>
            </Grid>
          </>
        }
        { record?.subscriptionType === subscriptionTypes.GREEN_PROMISE &&
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Contract Sent to Customer Date">
              <DateField source="contractSentToCustomer"></DateField>
            </Labeled>
          </Grid>
        }
        { record?.contractSentToFirstName &&
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Contract Sent to Name">
              <FunctionField render={record => `${record.contractSentToFirstName ?? ''} ${record.contractSentToLastName ?? ''}`}/>
            </Labeled>
          </Grid>
        }
        { record?.contractSentToEmail &&
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Contract Sent To Email">
              <TextField source="contractSentToEmail"></TextField>
            </Labeled>
          </Grid>
        }
        { record?.contractMarkedExecutedDate &&
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Contract Marked Executed Date">
              <DateField source="contractMarkedExecutedDate"></DateField>
            </Labeled>
          </Grid>
        }
        { record?.contractMarkedExecutedBy &&
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Contract Marked Executed By">
              <TextField source="contractMarkedExecutedBy"></TextField>
            </Labeled>
          </Grid>
        }
      </Grid>
      <Modal open={openSendInfo} onClose={handleCloseSendInfo}>
        <Grid style={modalStyle} className={classes.modal}>
          <SendToInformation subscription={record} onClose={handleCloseSendInfo}/>
        </Grid>
      </Modal>
    </>
  )
};
export default ContractInformation;
