import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import red from '@material-ui/core/colors/red';

const renewTheme = merge({}, defaultTheme, {
  palette: {
    background: {
        paper: "#fafafa",
        default: "#F1F1F1"
    },
    primary: {
        main: '#FF1A58'
    },
    secondary: {
        main: '#FFFFFF'//'#4FB947'
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    // targeting refresh button, hide for now
    RaAppBar: {
      toolbar: {
        
        '& button': {
          '&:not(:nth-child(1))': {
            display: 'none'
          }
        }
      }
    },
    RaLayout:{
      contentWithSidebar:{
        paddingTop: '1rem'
      }
    },
    MuiButton:{
      // root:{
      //   borderRadius: '2rem',
      //   border: '1px solid rgba(255, 26, 88, 0.5);'
      // },
      containedPrimary:{
        borderRadius: '2rem'
      },
      outlinedPrimary:{
        borderRadius: '2rem'
      }
    }
  },
});
export default renewTheme;