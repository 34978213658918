const getValidateSubscriptionContract = (subscriptionId, key) => {
  const options = { 
    method: 'GET'
  };
  return fetch(`/api/subscriptions/${subscriptionId}/contract/validate/${key}`, options)
           .then(res => res.json())
}

const postSignSubscriptionContract = (subscriptionId, name) => {
  const options = { 
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({"name": name})
  };
  return fetch(`/api/subscriptions/${subscriptionId}/contract/sign`, options)
           .then(res => res.json())
}


export {
  getValidateSubscriptionContract,
  postSignSubscriptionContract
}