export const statuses = [
  { id: 'Pending', name: 'Pending' },
  { id: 'Customer Contract Review', name: 'Customer Contract Review' },
  { id: 'Customer Contract Pending', name: 'Customer Contract Pending' },
  { id: 'Pending Enrollment', name: 'Pending Enrollment' },
  { id: 'Enrolled', name: 'Enrolled' },
  { id: 'Error - Validation', name: 'Error - Validation'},
  { id: 'Error - Enrollment', name: 'Error - Enrollment'},
  { id: 'Waitlist', name: 'Waitlist'},
  { id: 'Not Eligible', name: 'Not Eligible'},
  { id: 'Unenrolled', name: 'Unenrolled'}
]
