import ClientOAuth2 from 'client-oauth2';
import { getUserFromLocalStorage, isEmptyObject, setUser } from './utilFunctions';

const clientId = process.env.REACT_APP_CLIENT_ID;
const accessTokenUri = process.env.REACT_APP_TOKEN_URL;
const authorizationUri = process.env.REACT_APP_AUTH_URL;
const redirectUri = process.env.REACT_APP_REDIRECT_URL;

const userAuth = new ClientOAuth2({
  clientId: clientId,
  accessTokenUri: accessTokenUri,
  authorizationUri: authorizationUri,
  redirectUri: redirectUri
});



const handleError = (error) => {
  console.log(`Error on login: ${JSON.stringify(error)}`);
  //TODO additional error handling if needed
}

const authProvider = {
    // called when the user attempts to log in
    login: (url) => {
      if (isEmptyObject(url)) {      
        //redirect to saml login  
        const uri = userAuth.code.getUri();        
        window.location.replace(uri);
        return;
      }
      return userAuth.code.getToken(url.location)
        .then((response) => {  
          setUser(response.data);
        }).catch((error) => {
          handleError(error);
        })
    },
    // called when the user clicks on the logout button
    logout: () => {
      setUser(false);
      return Promise.resolve();   
    },
    // called when the API returns an error
    checkError: (error) => {
      const status = error?.status;
      if (status) {
        if (status === 401){
          return Promise.reject();
        }else if (status ===403){
          return Promise.reject({ logoutUser: false });
        }
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
      const jsonState = getUserFromLocalStorage();
      return jsonState ? Promise.resolve() : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
      // TODO: in future if we have roles (view, edit, etc)
      return Promise.resolve()
    },
    getIdentity: () => {
      //return Promise.resolve({ id, fullName, avatar });
      return Promise.resolve();
    }
}

export default authProvider;
