import { requireContract, subscriptionTypes } from "../constants/subscriptionTypes";

const setUser = (user) => {
  localStorage.setItem(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

const setPermissions = (permissions) => {
  localStorage.setItem(process.env.REACT_APP_PERMISSIONS_LOCAL_STORAGE_KEY, JSON.stringify(permissions));
}

const getUserFromLocalStorage = () => {
  const localState = localStorage.getItem(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY);
  const jsonState = (localState) ? JSON.parse(localState) : false;

  return jsonState;
}

const getPermissionsFromLocalStorage = () => {
  const localState = localStorage.getItem(process.env.REACT_APP_PERMISSIONS_LOCAL_STORAGE_KEY);
  const jsonState = (localState) ? JSON.parse(localState) : false;

  return jsonState;
}

const isEmptyObject = (obj) =>{
  return JSON.stringify(obj) === '{}';
}

const isContractViewable = (record) => {
  const status = record?.status;
  const subscriptionType = record?.subscriptionType;
  let isViewable = false;
  
  if(requireContract.includes(subscriptionType) &&
      (status === 'Customer Contract Review' || 
        status === 'Customer Contract Pending' ||
        status === 'Operating Company Contract Pending' ||
        status === 'Pending Enrollment' ||
        status === 'Enrolled' ||
        status === 'Unenrolled'
      )
    ){
    isViewable = true;
  }

  return isViewable
}

const isWaitlistStatus = (record) => {
  const status = record?.status;
  let isWaitlistStatus = false;
  if (status && status === 'Waitlist'){
    isWaitlistStatus = true;
  }
  return isWaitlistStatus;
}

const isEditable = (record) => {
  const status = record?.status;
  const subscriptionType = record?.subscriptionType;
  let isEditable = false;
  if(subscriptionType === subscriptionTypes.GEAUX_GREEN && (status === 'Waitlist' || status === 'Pending Enrollment' || status === 'Pending')){
    isEditable = true;
  }

  return isEditable
}

const isErrorStatus = (record) => {
  const status = record?.status;
  let isErrorStatus = false;
  if (status && status.startsWith("Error")){
    isErrorStatus = true;
  }
  return isErrorStatus;
}

const isValidated = (record) => {
  const status = record?.status;
  const subscriptionType = record?.subscriptionType;
  let isValidated = false;
  if( subscriptionType === subscriptionTypes.GREEN_PROMISE &&
      (status === 'Customer Contract Review' || 
        status === 'Customer Contract Pending' ||
        status === 'Operating Company Contract Pending' ||
        status === 'Pending Enrollment' ||
        status === 'Enrolled' ||
        status === 'Error - Enrollment' ||
        status === 'Unenrolled'
      )
    ){
    isValidated = true;
  } else if (subscriptionType === subscriptionTypes.RCO && status === 'Enrolled') {
    isValidated = true;
  } else if (subscriptionType === subscriptionTypes.GS_LARGE_VOLUME && 
      (status === 'Pending Enrollment' || 
       status === 'Enrolled' ||
       status === 'Error - Enrollment'
      )
    ) {
    isValidated = true;
  }

  return isValidated
}

const getOptionSelectedDisplay = (record, truncate=false) => {
  const optionSelected = record?.optionSelected;
  switch (optionSelected) {
    case "1yr":
      return "1 Year";
    case "10yr":
      return "10 Year";
    case "low-income":
      return "Low Income (1 Year)";
    case "tier1":
      return "Tier 1";
    case "tier2":
      return "Tier 2";
    case "tier3":
      return "Tier 3";
    case "fixedPrice":
      return "Fixed Price";
    case "variablePrice":
      return "Variable Price";
    case "largeVolume":
        return "Large Volume";
    case "gozerooptionone":
      return "Option 1 - RECS";
    case "gozerooptiontwo":
      return "Option 2 - AECS";
    case "gozerooptionthree":
      return "Option 3 - Time-Match Reporting";
    case "gozerooptionbundle":
        return "Bundled Options 2 and 3";
    default:
      if (truncate)
        return truncateForDisplay(optionSelected, 60);
      else
        return optionSelected;
  } 
}

const getFormattedDate = (val) => {
  const options = {
    year: "numeric",
    month:"2-digit",
    day:"2-digit",
    hour:  "2-digit",
    minute: "2-digit",
    second: "2-digit"
 };
 return new Date(val + "+00:00").toLocaleDateString("en-US", options).replace(', ','\n');
}

/**
 * Truncate string to n characters and append '...' to the end.
 * 
 * @param {string} str 
 * @param {int} n 
 * @returns 
 */
const truncateForDisplay = (str, n) => {
  return (str && str.length > n) ? str.substring(0, n) + '...' : str;
}

export {
  setUser,
  setPermissions,
  getUserFromLocalStorage,
  getPermissionsFromLocalStorage,
  isEmptyObject,
  isContractViewable,
  isErrorStatus,
  isValidated,
  getOptionSelectedDisplay,
  isWaitlistStatus,
  isEditable,
  getFormattedDate,
  truncateForDisplay
}