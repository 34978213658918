import { Grid, TextField, CircularProgress, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react";
import { postSendContract } from '../../api/AdminAPI';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "1rem"
  },
}));

const SendToInformation = props => {
  const classes = useStyles();
  const subscription = props?.subscription;
  const onClose = props?.onClose;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: subscription?.email ? subscription?.email : "",
    firstName: subscription?.firstName ? subscription?.firstName : "",
    lastName: subscription?.lastName ? subscription?.lastName : "", 
  });

  const handleChange = (event) => {    
    let key = event.target.name;
    let value = event.target.value;
    console.log(`${key}: ${value}`)
    setFormData({
        ...formData,
        [key]: value
    });
  };

  const validateRequired = () => {
    return (formData.email
        && formData.firstName
        && formData.lastName
    );

  }

  const validateEmailFormat = (email) => {
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
    return regex.test(email)
  }

  const enableSubmit = () => {
    return validateRequired()
        && validateEmailFormat(formData.email)

  }

  const handleSubmit = () => {
    setLoading(true);
    postSendContract(subscription?.id, formData).then(() => {
        setLoading(false);
        onClose();
    }).catch((error) => {
        setLoading(false);
        console.log(error);
    });
  }

  return (
    <div>
      <form noValidate autoComplete="false" onSubmit={() => handleSubmit()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Email Contract To:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              name="email"
              label="E-mail Address"
              inputMode="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button 
              className={classes.button} 
              disabled={loading} 
              onClick={onClose} 
              variant="outlined" 
              color="primary">
                Close
            </Button>
            {loading ? 
              <CircularProgress color="primary" size={36} /> 
              :
              <Button
                className={classes.button}
                id="submit"
                type="submit"
                variant="contained"
                color="primary"
                disabled={!enableSubmit()}
                onClick={handleSubmit}>
                  Send Email
              </Button>
            }
          </Grid>
        </Grid>
      </form>
    </div>
  )
};
export default SendToInformation;