import * as React from "react";
import { Admin, Resource } from 'react-admin';
import SubscriptionShow from "./components/subscriptions/SubscriptionShow";
import SubscriptionList from "./components/subscriptions/SubscriptionList";
// import Dashboard from './pages/Dashboard';
import renewDataProvider from './utils/renewDataProvider'
import authProvider from "./utils/authProvider";
import renewTheme from './themes/theme'
import customRoutes from "./utils/customRoutes";
import OauthLogin from './pages/OauthLogin';
import { createBrowserHistory as createHistory } from 'history';
import CustomLayout from "./components/CustomLayout";

const history = createHistory();

const App = () => (
  <Admin 
    layout={CustomLayout}
    theme={renewTheme}  
    dataProvider={renewDataProvider} 
    authProvider={authProvider}
    customRoutes={customRoutes}
    loginPage={OauthLogin}
    history={history}
  >
    <Resource name="subscriptions" list={SubscriptionList} show={SubscriptionShow}/>
  </Admin>    
)

export default App;
