import { CardContent, Grid, Typography, Divider, Modal, CircularProgress } from "@material-ui/core";
import { EmailField, TextField, Labeled, ChipField, FunctionField, useRecordContext, Button, useRefresh, useNotify, useDataProvider } from "react-admin";
import { getFormattedDate, getOptionSelectedDisplay, isContractViewable, isEditable, isErrorStatus, isValidated, isWaitlistStatus } from '../../utils/utilFunctions';
import ContractInformation from '../contract/ContractInformation';
import { makeStyles } from "@material-ui/core/styles"
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import WarningIcon from '@material-ui/icons/Warning';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useState } from 'react';
import SubscriptionEdit from "./SubscriptionEdit";
import { updateStatus } from "../../api/AdminAPI";
import ConfirmationDialog from "../ConfirmationDialog";
import { haveViewableAmounts, subscriptionTypes } from "../../constants/subscriptionTypes";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    background: '#fafafa',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem'
  }
}));

const SubscriptionInformation = props => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const [openEditCustInfo, setOpenEditCustInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [showConfirm, setShowConfirm] = useState(false)
  const [handleConfirm, setHandleConfirm] = useState(false)
  const [confirmDesc, setConfirmDescription] = useState("")

  const handleOpenEditCustInfo = () => {
    setOpenEditCustInfo(true);
  };

  const handleCloseEditCustInfo = () => {
    setOpenEditCustInfo(false);
    refresh();
  };

  const moveToPending = () => {
    confirmNewStatus("Pending Enrollment")
  }

  const moveToWaitlist = () => {
    confirmNewStatus("Waitlist")
  }

  const moveToNotEligible = () => {
    confirmNewStatus("Not Eligible")
  }

  const moveToEnrolled = () => {
    confirmNewStatus("Enrolled")
  }

  const resetForProcessing = () => {
    let newStatus = ""
    const currentStatus = record?.status
    if (currentStatus === "Error - Enrollment") {
      newStatus = "Pending Enrollment"
    }else if (currentStatus === "Error - Validation"){
      newStatus = "Pending"
    }
    if (newStatus){
      confirmNewStatus(newStatus)
    }
  }

  const confirmNewStatus = (newStatus) => {
    setShowConfirm(true)
    setHandleConfirm(() => () => updateSubscriptionStatus(newStatus)) 
    setConfirmDescription(`The subscription will be set to a status of ${newStatus}. Are you sure?`) 
  }

  const updateSubscriptionStatus = (newStatus) => {
      setLoading(true);
      updateStatus(record?.id, newStatus).then(() => {
        dataProvider.invalidateCache("subscriptions");
        refresh(true);
        setLoading(false);
        notify(`Subscription moved to ${newStatus}`);
      }).catch((error) => {
          console.log(error);
      });
  }

  const displayMultiple = (value) => {
    if (value?.includes(";")){
      const valueList = value.split(";");
      let retVal = ""
      for (let i=0; i< valueList.length; i++){
        retVal = retVal + valueList[i] + ", "
      }
      retVal = retVal.slice(0, -2); //remove last space and comma 
      return retVal;
    }
    return value;
  }

  return (
    <>
      <CardContent>
        <Grid container spacing={3}>
          {isContractViewable(record) &&
            <>
              <Grid item xs={12}>
                <Typography variant='h6'>Contract Information</Typography>
              </Grid>
              <Grid item xs={12}>
                <ContractInformation/>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
            </>
          }
          <Grid item xs={12}>
            <Typography variant='h6'>Subscription Information</Typography>
          </Grid>
          {isEditable(record) &&
            <Grid item container xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <Button 
                  variant="outlined"
                  onClick={handleOpenEditCustInfo} 
                  label={"Edit"}
                  startIcon={<EditIcon />}
                />
              </Grid> 
              {isWaitlistStatus(record) &&            
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    {loading && 
                      <CircularProgress  size={'1.6rem'}/>
                    }
                    {!loading && 
                      <Button
                        variant="outlined" 
                        onClick={moveToPending} 
                        label={"Move To Pending"}
                        startIcon={<PlaylistAddIcon />}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}></Grid>
                </>
              }
              {!isWaitlistStatus(record) &&            
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    {loading && 
                      <CircularProgress  size={'1.6rem'}/>
                    }
                    {!loading && 
                    <>
                      <Button
                        variant="outlined" 
                        onClick={moveToWaitlist} 
                        label={"Move To Waitlist"}
                        startIcon={<PlaylistAddIcon />}
                        style={{"marginRight": "1rem", "marginBottom": "1rem"}}
                      />
                      {/* TODO: remove ability to manually enroll/deny a pending subscription once bot is ready*/}
                      <Button 
                        variant="outlined"
                        onClick={moveToNotEligible} 
                        label={"Set as Not Eligible"}
                        startIcon={<WarningIcon />}
                        style={{"marginRight": "1rem", "marginBottom": "1rem"}}
                      />
                      <Button 
                        variant="outlined"
                        onClick={moveToEnrolled} 
                        label={`Set as Enrolled`}
                        startIcon={<ThumbUp />}
                        style={{"marginRight": "1rem", "marginBottom": "1rem"}}
                      />
                      </>
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}></Grid>
                </>
              }              
            </Grid>
          }
          {isErrorStatus(record) &&
            <>
              <Grid item container xs={12}>
                <Grid item xs={12} sm={6} md={4}>
                  {loading && 
                    <CircularProgress  size={'1.6rem'}/>
                  }
                  {!loading && 
                    <>
                      <Button 
                        variant="outlined"
                        onClick={moveToNotEligible} 
                        label={"Set as Not Eligible"}
                        startIcon={<WarningIcon />}
                        style={{"marginRight": "1rem", "marginBottom": "1rem"}}
                      />
                      <Button 
                        variant="outlined"
                        onClick={resetForProcessing} 
                        label={`Reset To ${record?.status === "Error - Enrollment" ? "Pending Enrollment" : "Pending"}`}
                        startIcon={<RotateLeftIcon />}
                        style={{"marginRight": "1rem", "marginBottom": "1rem"}}
                      />
                    </>
                  }
                </Grid>
              </Grid>
            </>
          }
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Customer Type">
              <TextField source="customerType" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Subscription Type">
              <TextField source="subscriptionType" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Operating Company">
              <TextField source="operatingCompany"/>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Sign Up Date">
              <FunctionField render={record => getFormattedDate(record?.createdDate)} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Status">
              <ChipField source="status" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Status Date">
              <FunctionField
                  render={record => getFormattedDate(record?.statusDate)}
                />
            </Labeled>
          </Grid>
          { isErrorStatus(record) &&
            <Grid item xs={12}>
              <Labeled label="Error Information" style={{color: "red"}}>
                <TextField source="statusInfo.errorDetails"/>
              </Labeled>
            </Grid>
          }        
          { isValidated(record) && 
            <>
              <Grid item xs={12}>
                <Typography variant='h7'>Validated Information</Typography>
              </Grid>
              <Grid item xs={12}>
                <Labeled label="Account Number(s)">
                  <FunctionField
                    render={record => displayMultiple(record?.accountNumber)}
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled label="Subscription kW">
                  <FunctionField
                    render={record => displayMultiple(record?.amount)}
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled label="Rate Schedule">
                  <FunctionField
                    render={record => displayMultiple(record?.rateSchedule)}
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled label="Service Location(s)">
                  <FunctionField
                    render={record => displayMultiple(record?.serviceLocation)}
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled label="Option Selected">
                  <FunctionField
                    render={record => getOptionSelectedDisplay(record)}
                  />
                </Labeled>
              </Grid>                       
            </>
          }
          <Grid item xs={12}>
            <Typography variant='h7'>Customer Request</Typography>
          </Grid>
          <Grid item xs={12}>
            <Labeled label="Account Number(s)">
              { isValidated(record) ? 
                <FunctionField
                    render={record => displayMultiple(record?.customerEnteredAccountNumber)}
                />
                :
                <FunctionField
                    render={record => displayMultiple(record?.accountNumber)}
                />
              }
            </Labeled>
          </Grid>
          
          {haveViewableAmounts.includes(record?.subscriptionType)  &&
            <Grid item xs={12}>
              <Labeled label="Requested Subscription kW">
                { isValidated(record) ? 
                  <FunctionField
                      render={record => displayMultiple(record?.customerEnteredAmount)}
                  />
                  :
                  <FunctionField
                      render={record => displayMultiple(record?.amount)}
                  />
                }
              </Labeled>
            </Grid>   
          }
          <Grid item xs={12} >
            <Labeled label="Option Selected">
              <FunctionField
                  render={record => getOptionSelectedDisplay(record)}
              />
            </Labeled>
          </Grid>
          {record?.subscriptionType === subscriptionTypes.GO_ZERO &&
            <>
            <Grid item xs={12}>
              <Labeled label="kW Amount">
                <FunctionField
                  render={record => displayMultiple(record?.amount)}
                />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <Labeled label="Billing Option">
                <FunctionField
                  render={record => displayMultiple(record?.billOption)}
                />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <Labeled label="AEC Percentage">
                <FunctionField
                  render={record => displayMultiple(record?.AECPercentage)}
                />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <Labeled label="Time-match Reporting">
                <FunctionField
                  render={record => displayMultiple(record?.timeMatchReporting)}
                />
              </Labeled>
            </Grid>
            </>
          }
          {record?.subscriptionType === subscriptionTypes.GEAUX_GREEN &&
            <Grid item xs={12} sm={6} md={4}>
              <Labeled label="Subscription Amount Requested (kW)">
                <TextField source="amount"/>
              </Labeled>
            </Grid>
          }
          {record?.wantedAmount &&
            <Grid item xs={12} sm={6} md={4}>
              <Labeled label="Subscription Amount If Not Capped (kW)">
                <TextField source="wantedAmount"/>
              </Labeled>
            </Grid>
          }
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Customer Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="First Name">
              <TextField source="firstName"/>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Last Name">
              <TextField source="lastName" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Business Name">
              <TextField source="businessName" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Address">
              <TextField source="streetAddress"/>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="City">
              <TextField source="city" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="State">
              <TextField source="state" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Zip">
              <TextField source="zip" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Phone Number">
              <TextField source="phoneNumber" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Labeled label="Email">
              <EmailField source="email" />
            </Labeled>
          </Grid>

        </Grid>
      </CardContent>
      <Modal open={openEditCustInfo} onClose={handleCloseEditCustInfo}>
        <Grid style={modalStyle} className={classes.modal}>
          <SubscriptionEdit subscription={record} onClose={handleCloseEditCustInfo}/>
        </Grid>
      </Modal>
      <ConfirmationDialog
        showModal={showConfirm}
        setShowModal={setShowConfirm}
        description={confirmDesc}
        onConfirmAction={handleConfirm}
      />
    </>
  )
};
export default SubscriptionInformation;
